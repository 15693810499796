import React from 'react';
import InfoBox from '../infoBox';
import { useSelector } from 'react-redux';
import { getStatistics } from '../../redux/monitor/selectors';
import Preloader from '../preloader';

export default React.memo(function Overview() {
  const statisticsData = useSelector(getStatistics);

  return !statisticsData ? <Preloader /> : (
    <div className={'statistics_overview_table'}>
      {Object.keys(statisticsData).map((key, i) =>
      <InfoBox data={statisticsData[key]} key={i} dataKey={key}/>
      )}
    </div>
  );
})
