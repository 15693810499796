export class SocketController {
  constructor(authData, onMessage) {
    const { url } = authData;
    this.requestId = 0;
    this.responseCallbacks = {};
    this.callStack = [];
    this.externalOnMessage = onMessage;
    this.url = url;
    this.reconnectTimout = null;
    this.authData = authData;

    this.connect = this.connect.bind(this);
    this.onMessage = this.onMessage.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onError = this.onError.bind(this);
    this.reconnect = this.reconnect.bind(this);

    this.connect(url, onMessage);
  }

  connect(url, onMessage) {
    this.ws = new WebSocket(url);
    this.ws.addEventListener('message', onMessage);
    this.ws.addEventListener('message', this.onMessage);
    this.ws.addEventListener('open', this.onOpen);
    this.ws.addEventListener('error', this.onError);
    this.ws.addEventListener('close', this.reconnect);
  }

  onOpen() {
    clearTimeout(this.reconnectTimout);
    this.reconnectTimout = null;
    if (this.ws.readyState !== 1) return this.onOpen();
    console.log('ws is open');

    const data = {
      id: 'auth',
      sessionHash: this.authData.hash,
      sessionId: this.authData.sid,
    };
    console.log(data);
    this.authData && this.send(data);

    while (this.callStack.length > 0) {
      const item = this.callStack.shift();
      this._sendWithPromise(item.message, item.resolve, item.reject);
    }
  }

  onError(event) {
    console.error(event.message);
  }

  reconnect() {
    console.log('reconnecting to ws...');
    this.reconnectTimout = setTimeout(() => {
      this.close();
      this.connect(this.url, this.externalOnMessage)
    }, 5000);
  }

  async onMessage(event) {
    let data = '';
    data = JSON.parse(event.data);
    if (data.code) {
      console.error(data.message);
      if (data.hasOwnProperty('requestId')) {
        this.responseCallbacks[data.requestId].reject(data);
        delete this.responseCallbacks[data.requestId];
      }
    }
    if (data.hasOwnProperty('requestId')) {
      if (data.requestId === -1 || !this.responseCallbacks[data.requestId]) return;

      this.responseCallbacks[data.requestId].resolve(data);
      delete this.responseCallbacks[data.requestId];
    }
  }

  send(message) {
    if (!navigator.onLine) {
      throw new Error('No Internet connection.')
    }

    if (message.id === 'admin') {
      message.key = this.authData.key;
    }

    return new Promise((resolve, reject) => {
      try {
        this._sendWithPromise(message, resolve, reject);
      } catch (e) {
        console.warn(e.message);
        this.callStack.push({ message, resolve, reject });
      }
    })
  }

  close() {
    this.ws.removeEventListener('message', this.externalOnMessage);
    this.ws.removeEventListener('message', this.onMessage);
    this.ws.removeEventListener('open', this.onOpen);
    this.ws.removeEventListener('error', this.onError);
    this.ws.removeEventListener('close', this.reconnect);
    this.ws.close();
    this.ws = null;
    console.log('ws is closed');
  }

  _sendWithPromise(message, resolve, reject) {
    this.ws.send(JSON.stringify({ ...message, requestId: this.requestId }));
    this.responseCallbacks[this.requestId] = { resolve, reject };
    this.requestId++;
  }
}
