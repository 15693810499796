const SET_IS_AUTH = 'MONITOR/SET_IS_AUTH';
const SET_SOCKET_URL = 'MONITOR/SET_SOCKET_URL';
const SET_STATISTICS = 'MONITOR/SET_STATISTICS';
const SET_SESSIONS_LIST = 'MONITOR/SET_SESSIONS_LIST';
const SET_SESSION = 'MONITOR/SET_SESSION';

const initialState = {
  isAuth: false,
  socketUrl: null,
  statistics: null,
  sessionsList: [],
  session: null
};

export default function monitorReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_IS_AUTH:
      return {
        ...state,
        isAuth: payload,
      };
    case SET_SOCKET_URL:
      return {
        ...state,
        socketUrl: payload,
      };
    case SET_STATISTICS:
      return {
        ...state,
        statistics: payload,
      };
    case SET_SESSIONS_LIST:
      return {
        ...state,
        sessionsList: payload,
      };
    case SET_SESSION:
      return {
        ...state,
        session: payload,
      };
    default:
      return state;
  }
}


export const monitorActions = {
  setAuth(payload) {
    return {
      type: SET_IS_AUTH,
      payload
    }
  },

  setSocketUrl(payload) {
    return {
      type: SET_SOCKET_URL,
      payload
    }
  },

  setStatistics(payload) {
    return {
      type: SET_STATISTICS,
      payload
    }
  },

  setSessionsList(payload) {
    return {
      type: SET_SESSIONS_LIST,
      payload
    }
  },

  setSession(payload) {
    return {
      type: SET_SESSION,
      payload
    }
  },
};
