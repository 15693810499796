import React, { useCallback, useMemo, useState } from 'react';
import Overview from './overview';
import Sessions from './sessions';
import { statisticsViewTypes } from './viewTypes';
import { useSelector } from 'react-redux';
import { getSocketUrl } from '../../redux/monitor/selectors';

export default React.memo(function Statistics() {
  const socketUrl = useSelector(getSocketUrl);
  const [currentView, setCurrentView] = useState(statisticsViewTypes.OVERVIEW);

  const changeViewType = useCallback((e) => {
    setCurrentView(statisticsViewTypes[e.target.innerText.toUpperCase()]);
  }, [setCurrentView]);

  const view = useMemo(() => {
    switch (currentView) {
      case statisticsViewTypes.OVERVIEW:
        return <Overview />;
      case statisticsViewTypes.SESSIONS:
        return <Sessions />;
      default:
        return null;
    }
  }, [currentView]);

  return (
    <div className={'page'}>
      <span className={'statistics_url'}>
        {socketUrl}
      </span>

      <nav className={'statistics_nav'}>
        {Object.values(statisticsViewTypes).map((type, i) =>
          <span key={i} className={`button ${currentView === type ? 'statistics_nav_active' : ''}`}
                onClick={changeViewType}>{type}</span>
        )}
      </nav>

      {view}

    </div>
  );
})
