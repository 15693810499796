import React, { useCallback, useEffect, useMemo } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import Statistics from './components/statistics';
import AuthModal from './modals/auth';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAuth } from './redux/monitor/selectors';
import * as queryString from 'querystring';
import { managerWS } from './index';
import { monitorActions } from './redux/monitor/reducer';

export default React.memo(function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuth = useSelector(getIsAuth);

  const queryData = useMemo(() => {
    try {
      const parsedData = queryString.parse(history.location.search.substr(1));
      return parsedData.connection ? JSON.parse(parsedData.connection) : {};
    } catch (e) {
      console.error(e);
    }
  }, [history.location]);

  const auth = useCallback((data) => {
    managerWS.connect(data);
    dispatch(monitorActions.setAuth(true));
    dispatch(monitorActions.setSocketUrl(data.url));
  }, [dispatch]);

  useEffect(() => {
    if (isAuth) return;
    if (!queryData || !queryData.url || !queryData.sid || !queryData.hash || !queryData.key) {
      return history.push({
        search: ''
      });
    }

    auth(queryData);
  }, [queryData, auth, history, isAuth]);

  return (
    <div className={'app'}>
      <Switch>
        <Route exact path={'/'} render={() => isAuth ? <Statistics /> : <AuthModal auth={auth} />} />
      </Switch>
    </div>
  );
})
