import React from 'react';

export default React.memo(function InfoBox({ data, dataKey }) {

  const renderList = (data, key, currentIndex) => {
    if (typeof data === 'object') {
      return <div key={currentIndex + key}>
        {key && <span className={'info_box_body_key'}>{key}:</span>}
        {Object.keys(data).map(localKey => renderList(data[localKey], localKey, currentIndex++))}
      </div>
    }

    return <div className={`info_box_body_row ${currentIndex % 2 === 0 ? 'info_box_body_row_light_up' : ''}`}
                key={currentIndex + key}>
      <span>{key}:</span>
      <span>{data}</span>
    </div>
  };

  return (
    <div className={'info_box'}>
      <span className={'info_box_title'}>{dataKey}</span>
      <div className={'info_box_body'}>
        {renderList(data, dataKey, 0)}
      </div>
    </div>
  );
})
