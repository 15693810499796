import React from 'react';

export default React.memo(function Spinner() {
  return (
    <div className={'loading_box'}>
      <div className="lds_spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
})
