import React, { useCallback, useEffect } from 'react';

export default React.memo(function BaseModal({ visible, onClose, showCloseButton, showOnTop, children }) {

  const onKeyDown = useCallback((e) => {
    if (e.keyCode !== 27) return;

    onClose();
  }, [onClose]);

  useEffect(() => {
    if (!showCloseButton) return;

    if (visible) {
      window.addEventListener('keydown', onKeyDown);
    } else {
      window.removeEventListener('keydown', onKeyDown);
    }

    return () => window.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown, visible, showCloseButton]);

  const stopBubble = (e) => {
    e.stopPropagation();
  };

  return (
    <div onClick={onClose}
         className={`modal_wrapper ${visible ? 'modal_wrapper_visible' : ''} ${showOnTop ? 'modal_wrapper_error' : ''}`}>
      <div onClick={stopBubble} className={'modal_container'}>
        {showCloseButton && <span onClick={onClose} className={'modal_x_button'}>×</span>}
        {children}
      </div>
    </div>
  );
})
