import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BaseModal from './baseModal';

export default React.memo(function AuthModal({ auth }) {
  const history = useHistory();
  const [authForm, setAuthForm] = useState({
    url: '',
    hash: '',
    sid: '',
    key: '',
  });

  useEffect(() => {
    const lastData = localStorage.getItem('game_monitor_last_auth_data');
    if (!lastData) return;

    try {
      const parsedData = JSON.parse(lastData);
      if (parsedData._expire < Date.now()) {
        return localStorage.removeItem('game_monitor_last_auth_data');
      }

      delete parsedData._expire;
      setAuthForm(parsedData);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const onSubmit = useCallback(() => {
    if (!authForm.sid || !authForm.hash || !authForm.key || !authForm.url) return;

    const expired = Date.now() + (1000 * 86400);
    localStorage.setItem('game_monitor_last_auth_data', JSON.stringify({ ...authForm, _expire: expired }));

    auth(authForm);

    const connection = encodeURIComponent(JSON.stringify(authForm));
    history.push({
      search: `?connection=${connection}`,
    });
  }, [authForm, history, auth]);

  const onKeyDown = useCallback((e) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  }, [onSubmit]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => document.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown]);

  const onInputChange = (e) => {
    setAuthForm(prev => ({
      ...prev,
      [e.target.dataset.field]: e.target.value
    }));
  };

  return (
    <BaseModal visible>
      <div className={'modal modal_m'}>
        <div className={'modal_header'}>
          AUTH
        </div>

        <div className={'modal_body'}>
          <div className={'form_block'}>
            <label>URL:</label>
            <input value={authForm.url} onChange={onInputChange} data-field={'url'}
                   autoComplete={'new-password'} />
          </div>

          <div className={'form_block'}>
            <label>HASH:</label>
            <input value={authForm.hash} onChange={onInputChange} data-field={'hash'}
                   autoComplete={'new-password'} />
          </div>

          <div className={'form_block'}>
            <label>SID:</label>
            <input value={authForm.sid} onChange={onInputChange} data-field={'sid'}
                   autoComplete={'new-password'} />
          </div>

          <div className={'form_block'}>
            <label>KEY:</label>
            <input value={authForm.key} onChange={onInputChange} data-field={'key'}
                   autoComplete={'new-password'} />
          </div>
        </div>

        <div className={'modal_controls'}>
          <span onClick={onSubmit} className={'button'}>Submit</span>
        </div>
      </div>
    </BaseModal>
  );
})
