import { SocketController } from './index';
import { monitorActions } from '../redux/monitor/reducer';

export default class ManagerWS {
  constructor(store) {
    this.lastErrorCode = 0;
    this.store = store;

    // this.domain = `wss://${window.location.hostname}`;
    // if ((/(localhost|192.168.1.54)/i).test(this.domain)) {
    //   this.domain = `ws://192.168.1.64:12010`;
    // }

    this.send = this.send.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.onMessage = this.onMessage.bind(this);
  }

  connect(data) {
    this.authData = data;
    this.ws = new SocketController(data, this.onMessage, data);
  }

  async onMessage(event) {
    let wsIncome = '';
    wsIncome = JSON.parse(event.data);
    const { id, type, ...data } = wsIncome;

    if (id === 'auth') {
      this.send({
        id: 'admin',
        key: this.authData.key,
        type: 'monitor'
      });

      return;
    }

    if (id === 'admin') {
      switch (type) {
        case 'monitor':
          if (data.success) return;

          return this.store.dispatch(monitorActions.setStatistics(data));

        case 'getsessions':
          return this.store.dispatch(monitorActions.setSessionsList(data.sessions));

        case 'getsessionbyid':
          return this.store.dispatch(monitorActions.setSession(data.session));
        default:
          console.error('Unknown response type');
      }


    }
  }

  getSessions() {
    this.send({
      id: 'admin',
      type: 'getsessions'
    });
  }

  getSessionById(id) {
    this.send({
      id: 'admin',
      type: 'getsessionbyid',
      sessionId: id,
    });
  }

  send(message) {
    return this.ws.send(message);
  }

  disconnect() {
    this.ws?.close();
  }
}
