import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionsList, getSession } from '../../redux/monitor/selectors';
import Preloader from '../preloader';
import { managerWS } from '../../index';
import ReactJson from 'react-json-view';
import { monitorActions } from '../../redux/monitor/reducer';

export default React.memo(function Overview() {
  const dispatch = useDispatch();
  const sessionsList = useSelector(getSessionsList);
  const session = useSelector(getSession);

  const getSessions = useCallback(() => {
    managerWS.getSessions();
  }, []);

  useEffect(() => {
    getSessions();

    return () => {
      dispatch(monitorActions.setSession(null));
    }
  }, [getSessions, dispatch]);

  const getSessionByKey = (key) => () => {
    managerWS.getSessionById(key);
  };

  return !sessionsList.length ? <Preloader /> : (
    <div className={'sessions'}>
      <div className={'sessions_list'}>
        <div>
          {sessionsList.map((sessionKey, i) =>
            <span key={i} onClick={getSessionByKey(sessionKey)}>{sessionKey}</span>
          )}
        </div>
      </div>
      <div className={'sessions_session_details'}>
        {session ?
          <ReactJson src={session} collapsed={1} collapseStringsAfterLength={20}
                     name={`Session ${session.sessionId}`} />
          :
          <span className={'placeholder'}>No data</span>
        }
      </div>
    </div>
  );
})
